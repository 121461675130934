import React from "react";
import cn from "clsx";
import { Button, Text } from "@components/ui";
import { useTranslation } from "next-i18next";
import { PrismicRichText } from "@prismicio/react";
import { useCustomer, useMotivationScore } from "@middleware/hooks";
import { canShowMotivationScoreBar } from "@middleware/helpers";
import style from "./MotivationScoreBar.module.scss";
import { Pressable } from "../Pressable/Pressable";

export default function MotivationScoreBar() {
  const { t } = useTranslation();
  const { content, scoreValue, setScoreValue, canRate, setMotivationScore } =
    useMotivationScore();
  const { customer } = useCustomer();
  const showScoreBar = canShowMotivationScoreBar(canRate, content, customer);

  return showScoreBar ? (
    <div className={style.bar}>
      <div>
        <div className={style.wrapper}>
          <div className={style.text}>
            <Text variant="p">
              <PrismicRichText field={content?.question} />
            </Text>
          </div>
          <div className={style.score}>
            <div className={style.flex}>
              <span>{content?.prefix}</span>
              <ul>
                {Array.from({ length: 5 }, (_, i) => i + 1).map((number) => (
                  <li key={number}>
                    <Pressable
                      className={cn({ [style.active]: number === scoreValue })}
                      onClick={() => setScoreValue(number)}
                    >
                      {number}
                    </Pressable>
                  </li>
                ))}
              </ul>
              <span>{content?.suffix}</span>
            </div>
          </div>
          <div className={style.action}>
            <Button
              variant="default"
              onClick={() => setMotivationScore()}
              disabled={scoreValue === undefined}
            >
              {t("buttons.iValidate")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
